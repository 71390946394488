import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import CommonFunctions from '@/components/Utility/Common'
import BaseFunctions from '@/components/Utility/Base'
import MembersDataService from '@/services/MembersDataService'
import ClubsDataService from '@/services/ClubsDataService'
import SysMember, { ClubRoleType } from '@/types/SysMember'
import SysClub from '@/types/SysClub'

type dataReturnType = { klubbers: any; medlemers: any; error: any; totalPages: number; }

export default class theMemberList extends Vue {
  private medlemers: SysMember[] = []
  private klubbers: SysClub[] = []
  error: any = null
  memberSearchInputValue = ''
  memberSearchTerm = ''
  private currentListSortingOrder = 'user_id.lastname:asc,user_id.firstname:asc'
  private oldSort = ''
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  filterByClubValue = 'Vælg klub'
  filterByClubOptions: string[] = []
  clubFilter = ''

  readonly name : string = 'theMemberList'
  data (): dataReturnType {
    return {
      medlemers: this.medlemers,
      error: this.error,
      totalPages: this.totalPages,
      klubbers: this.klubbers
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveMembers()
  }

  @Watch('filterByClubValue')
  onClubFilterValueChange (clubVal: any) : void {
    if (this.filterByClubValue !== 'Vælg klub') {
      this.clubFilter = 'klubber_id.klubber_klubnavn=' + clubVal.toString()
    }

    this.currentPage = 1
    this.retrieveMembers()
  }

  public convertToSlug (text: string) : string {
    return CommonFunctions.slugify(text)
  }

  public toMemberInfo (id: number, foreName: string, surName: string) : void {
    const memberSlug = id.toString() + '-' + this.convertToSlug(foreName) + '_' + this.convertToSlug(surName)

    // console.log(memberSlug)
    this.$router.push({ name: 'Memberinfo', params: { slug: memberSlug } })
  }

  public turnToNewPage (pageChange: number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMembers()
  }

  public jumpToPage (pageNumber: number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveMembers()
  }

  public findMember () : void {
    // console.log('Searching members.')
    this.memberSearchTerm = this.memberSearchInputValue
    this.retrieveMembers()
  }

  public columnSort (sortBy: string) : void {
    if (sortBy === 'reset') {
      this.memberSearchTerm = ''
      this.memberSearchInputValue = ''
      this.currentListSortingOrder = 'user_id.lastname:asc,user_id.firstname:asc'
      this.oldSort = ''
      this.currentPage = 1
      this.clubFilter = ''
      this.filterByClubValue = 'Vælg klub'
    }
    if (sortBy === 'licens') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'medlem_licens:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'medlem_licens:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'navn') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'user_id.lastname:desc,user_id.firstname:desc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'user_id.lastname:asc,user_id.firstname:asc'
        this.oldSort = sortBy
      }
    }
    if (sortBy === 'klub') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'klubber_id.klubber_klubnavn:desc'
        this.oldSort = ''
      }
    }

    this.retrieveMembers()
  }

  public fillClubOptions () : void {
    this.filterByClubOptions = []

    for (const klub of this.klubbers) {
      this.filterByClubOptions.push(klub.klubber_klubnavn)
    }

    // console.log(this.filterByClubOptions)
  }

  public retrieveClubs () : void {
    // console.log('Fetching active clubs from API.')

    ClubsDataService.getAll('', null, 'klubber_status=true')
      .then((response) => {
        this.klubbers = response.data
        // console.log(this.klubbers)
        this.fillClubOptions()
      })
      .catch((err) => {
        this.error = err
      })
  }

  public retrieveMembers () : void {
    BaseFunctions.getDatetimeFromServer()
      .then((response) => {
        const rawData = response.data ?? ''
        const formattedData = String(rawData).trim()

        const todaysDate = new Date(formattedData.length > 9 ? Date.parse(formattedData) : Date.now()).toISOString().split('T')[0]

        const parameters = `id_ne=17&${this.clubFilter}&medlem_licens_slut_gte=${todaysDate}`

        MembersDataService.getCount(parameters)
          .then((response) => {
            this.totalPages = response.data
            this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
            // console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
          })
          .catch((err) => {
            this.error = err
          })

        // console.log('Fetching member data from API.')

        if (this.memberSearchTerm.length > 0) {
          MembersDataService.findByNameAndLicenseSearchTerm(this.memberSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, parameters)
            .then((response) => {
              this.medlemers = response.data
              this.forceRerenderMemberList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        } else {
          MembersDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, parameters)
            .then((response) => {
              this.medlemers = response.data
              this.forceRerenderMemberList()
              this.$forceUpdate()
            })
            .catch((err) => {
              this.error = err
            })
        }
      })
      .catch((err) => {
        this.error = err
      })

    // console.log('Counting all active members')
  }

  public forceRerenderMemberList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  async mounted () : Promise<void> {
    this.retrieveMembers()
    this.retrieveClubs()
  }
}
